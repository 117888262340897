<template>
  <div v-if="!!doc" class="rounded-lg bg-white shadow">
    <div
      v-if="error"
      class="flex w-full items-center justify-between space-x-5 p-6 cursor-pointer"
      @click="deleteErroredDoc"
    >
      <XMarkIcon class="h-5 w-5 text-red-500" />
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="truncate text-sm font-medium text-red-500">Document upload error</h3>
        </div>
        <p class="mt-1 truncate text-sm text-gray-500">Click here to remove</p>
      </div>
      <ExpiryIndicator
        data-testid="expiry-indicator"
        v-if="!!doc.expiryDate"
        :expiryDate="doc.expiryDate"
      ></ExpiryIndicator>
    </div>

    <div
      v-else-if="doc.status === 'COMPLETE'"
      class="flex w-full items-center justify-between space-x-5 p-6"
      :class="!!doc.archivedAt && 'opacity-40'"
    >
      <template v-if="!doc.workspaceId">
        <LockClosedIcon v-if="!doc.public" class="h-5 w-5" />
        <EyeIcon v-if="doc.public" class="h-5 w-5" />
      </template>
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="truncate text-sm font-medium text-gray-900">
            {{ doc.name || doc.certificateTypeId || 'none' }}
          </h3>
        </div>
        <p class="mt-1 truncate text-sm text-gray-500">
          <span v-if="!!doc.archivedAt"
            >Archived
            {{ formatDistance(new Date(doc.archivedAt), new Date(), { addSuffix: true }) }}</span
          >
          <span v-if="!!doc.workspaceId" class="text-blue-500">Added by your connection</span>
          <span v-else>
            <span v-if="!doc.public">Private document</span>
            <span v-if="doc.public">Part of your public profile</span>
          </span>
        </p>
      </div>
      <ExpiryIndicator
        data-testid="expiry-indicator"
        v-if="!!doc.expiryDate"
        :expiryDate="doc.expiryDate"
      ></ExpiryIndicator>
      <div v-else class="h-12"></div>
    </div>

    <div v-else class="flex w-full items-center justify-between space-x-5 p-6">
      <ion-spinner v-if="doc.status === 'UPLOADING'" class="h-5 w-5" />
      <DocumentCheckIcon v-else class="h-5 w-5 animate-bounce" />
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="truncate text-sm font-medium text-gray-900">
            {{ doc.status === 'UPLOADING' ? 'Uploading...' : 'Ready to complete' }}
          </h3>
        </div>
        <p class="mt-1 truncate text-sm text-gray-500">Added {{ createdAt }}</p>
      </div>
      <ExpiryIndicator
        data-testid="expiry-indicator"
        v-if="!!doc.expiryDate"
        :expiryDate="doc.expiryDate"
      ></ExpiryIndicator>
    </div>
  </div>
</template>

<script setup lang="ts">
import ExpiryIndicator from '@/components/ExpiryIndicator.vue'
import { useModalStore } from '@/stores/modal'
import type { DocumentData } from '@/types/types'
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { DocumentCheckIcon, EyeIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useQueryClient } from '@tanstack/vue-query'
import axios from 'axios'
import { formatDistance, sub } from 'date-fns'
import { computed } from 'vue'
const props = defineProps<{
  doc?: DocumentData
}>()

const { presentLoader, dismissLoader } = useModalStore()
const queryClient = useQueryClient()

const deleteErroredDoc = async () => {
  presentLoader()
  const { data } = await axios.post(`/crewdentialsProfile/deleteIncompleteDoc/${props.doc!.id}`)
  dismissLoader()
  if (data) queryClient.invalidateQueries({ queryKey: ['documents'] })
}

const fiveMinsAgo = sub(new Date(), { minutes: 5 }).toISOString()

const error = computed(() => {
  return props.doc?.status === 'UPLOADING' && props.doc.createdAt < fiveMinsAgo
})

const createdAt = !!props.doc
  ? formatDistance(new Date(props.doc.createdAt), new Date(), { addSuffix: true })
  : null
</script>

import { defaultConfig } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { docInput } from '@/formkit/inputs/docInput'
import { rootClasses } from './formkit.theme'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'

// TODO: Only include inputs that are used
const pro = createProPlugin('fk-42520ec89ae', {
  ...inputs
})

export default defaultConfig({
  plugins: [pro as any],
  config: {
    rootClasses,
    classes: generateClasses({
      global: {
        label: 'font-semibold text-xs',
        inner: 'relative',
        selectionWrapper: 'rounded-lg bg-gray-50',
        message: 'pb-0',
        fieldset: 'relative',
        groupLabel: 'p-3 text-lg block font-semibold',
        listitemGroup: 'border-b'
      },
      repeater: {
        content: '!p-3',
        legend: 'font-semibold text-xs',
        addButton: '!max-w-52'
      },
      rating: {
        inner: '!bg-white'
      },
      docInput: {
        inner: '!bg-transparent shadow-none'
      },
      autocomplete: {
        input: 'text-ellipsis pr-0'
      },
      submit: {
        outer: 'p-0 mb-0',
        input: 'w-full justify-center'
      },
      button: {
        outer: 'p-0 pb-0',
        input: 'w-full justify-center bg-white text-gray-900 border-gray-300 hover:bg-gray-50'
      }
    }) as any
  },
  icons: { ...genesisIcons },
  inputs: {
    docInput
  }
})
